
















































































































import { Component, Vue } from 'vue-property-decorator';
// import { Store } from 'vuex';
// import { readUserProfile } from '@/store/main/getters';
import { readUserItems } from '@/store/main/getters';
import { dispatchGetUserItemsPending } from '@/store/main/actions';
import { readItemCreateError } from '@/store/main/getters';
import { dispatchProposeWalletWithdrawal } from '@/store/main/actions';
import { readConstants } from '@/store/main/getters';
import { readUserProfile, readUserWhitelistAddresses } from '@/store/main/getters';
import { dispatchGetUserProfile, dispatchGetConstants, dispatchGetUserWhitelistAddresses } from '@/store/main/actions';
import { readIsLoggedIn } from '@/store/main/getters';


@Component
export default class Dashboard extends Vue {
  public tokenTypes = ['USDT', 'USDC', 'ETH'];
  public blockchainTypes = ['TRC20', 'BEP20', 'Polygon'];

  public tokenType: string = '-';
  public tokenImgURL: string = ""; //require('@/assets/images/token_USDT.png');
  public walletHoldings: number = 0;
  public blockchainType: string = '-';

  public deposit_address = "";

  public valid = false;

  get userProfile() {
    return readUserProfile(this.$store);
  }

  get loggedIn() {
    return readIsLoggedIn(this.$store);
  }

  public changedValue(value) {
    if (this.tokenTypes.includes(this.tokenType) == false) {
      this.tokenType = this.tokenTypes[0];
    }

    // change supported blockchain for each token, and update user wallet holdings
    if (this.tokenType == 'USDT') {
      this.blockchainTypes = ['TRC20', 'BEP20', 'Polygon'];
      this.tokenImgURL = require('@/assets/images/token_USDT.png');
      if (this.userProfile) {
        this.walletHoldings = this.userProfile.wallet_USDT;
      }
    } else if (this.tokenType == 'USDC') {
      this.blockchainTypes = ['BEP20', 'Polygon'];
      this.tokenImgURL = require('@/assets/images/token_USDC.png');
      if (this.userProfile) {
        this.walletHoldings = this.userProfile.wallet_USDC;
      }
    } else if (this.tokenType == 'BUSD') {
      this.blockchainTypes = ['BEP20'];
      this.tokenImgURL = require('@/assets/images/token_BUSD.png');
      if (this.userProfile) {
        this.walletHoldings = this.userProfile.wallet_BUSD;
      }
    } else if (this.tokenType == 'BTC') {
      this.blockchainTypes = ['BEP20'];
      this.tokenImgURL = require('@/assets/images/token_BTC.png');
      if (this.userProfile) {
        this.walletHoldings = this.userProfile.wallet_BTC;
      }
    } else if (this.tokenType == 'ETH') {
      this.blockchainTypes = ['ERC20', 'BEP20'];
      this.tokenImgURL = require('@/assets/images/token_ETH.png');
      if (this.userProfile) {
        this.walletHoldings = this.userProfile.wallet_ETH;
      }
    } else if (this.tokenType == 'BNB') {
      this.blockchainTypes = ['BEP20'];
      this.tokenImgURL = require('@/assets/images/token_BNB.png');
      if (this.userProfile) {
        this.walletHoldings = this.userProfile.wallet_BNB;
      }
    } 

    if (this.blockchainTypes.includes(this.blockchainType) == false) {
      this.blockchainType = this.blockchainTypes[0];
    }

    // change deposit address
    if (this.userProfile) {
      if (this.blockchainType == 'TRC20') {
        this.deposit_address = this.userProfile.bind_TRC20_address;
      } else if (this.blockchainType == 'ERC20') {
        this.deposit_address = this.userProfile.bind_ERC20_address;
      } else if (this.blockchainType == 'BEP20') {
        this.deposit_address = this.userProfile.bind_ERC20_address;
      } else if (this.blockchainType == 'Polygon') {
        this.deposit_address = this.userProfile.bind_ERC20_address;
      }
    }
  }

  created() {
    if (this.userProfile) {
        this.walletHoldings = this.userProfile.wallet_USDT;
    }
    window.addEventListener('resize', this.onResize);
    this.onResize();
    this.checkVersion();
  }

  async mounted() {
    await dispatchGetConstants(this.$store);
    await dispatchGetUserProfile(this.$store);
    await dispatchGetUserWhitelistAddresses(this.$store);
  }

  // update version
  checkVersion() {
    const constants = readConstants(this.$store);

    let onlineVersion = "none";
    if (constants) {
      onlineVersion = constants.version;
    }

    let localVersion = localStorage.getItem('vers');
    let updateSignal = localStorage.getItem('upt');

    // check if we need to fresh the browser
    if (onlineVersion != "none" && localVersion != onlineVersion) { // detect newer online version
      localStorage.setItem('vers', onlineVersion);
      window.location.reload();
    } else if (updateSignal == 'refresh') { // detect refresh signal
      localStorage.setItem('upt', 'no');
      window.location.reload();
    }
  }

  // adaptive screen size
  public isMobile = false;

  beforeDestroy() {
    if (typeof window !== 'undefined') {
      window.removeEventListener('resize', this.onResize);
    }
  }

  onResize() {
    this.isMobile = window.innerWidth < 960;
  }
}

